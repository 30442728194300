<template>
   <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('tpm_report.training_summary_report') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
            <b-col lg="12" sm="12">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                  <b-row>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="search.fiscal_year_id"
                              :options="fiscalYearList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Circular Memo No" vid="circular_memo_no">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="circular_memo_no"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_iabm.circular_memo_no')}}
                          </template>
                          <b-form-select
                              plain
                              v-model="search.circular_memo_no"
                              :options="circularList"
                              id="circular_memo_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Organization"  vid="org_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="org_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.organization')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.org_id"
                            :options="organizationList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <!-- <b-col lg="6" sm="6">
                      <ValidationProvider name="Training Type"  vid="training_type_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="training_type_id"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_type')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_type_id"
                            :options="trainingTypeList"
                            id="training_type_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col> -->
                    <b-col lg="6" sm="6">
                      <ValidationProvider name="Training Title" vid="training_title_id">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleDataList"
                            id="training_title_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button size="sm" variant="primary" class="" @click="searchData">
                        <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                      </b-button>
                      &nbsp;
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-col>
          </b-row>
        </template>
      </card>
      <b-row>
        <b-col md="12" v-if="showData">
          <b-overlay>
            <b-row v-if='datas.length > 0'>
              <b-col md="12">
                <body-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('tpm_report.training_summary_report') }}</h4>
                  </template>
                  <template v-slot:headerAction>
                    <b-button class="btn_add_new" @click="pdfExport">
                      <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                    </b-button>
                  </template>
                  <template v-slot:body>
                    <b-overlay :show="loadingData">
                      <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="search.org_id ? search.org_id : 10">
                        <template v-slot:projectNameSlot>
                          {{ }}
                        </template>
                        {{ $t('tpm_report.training_summary_report') }}
                      </list-report-head>
                      <b-row>
                        <b-col md="6">
                          <span>{{ $t('elearning_config.fiscal_year') }}</span>:
                          <strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchHeaderData.fiscal_year_en : searchHeaderData.fiscal_year_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="6" class="text-right">
                          <span>{{ $t('elearning_config.organization') }}</span>:
                          <strong>{{ search.org_id ? ($i18n.locale === 'en' ? searchHeaderData.org_en : searchHeaderData.org_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="6">
                          <span>{{ $t('elearning_iabm.circular_memo_no') }}</span>:
                          <strong>{{ search.circular_memo_no ? search.circular_memo_no : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="6" class="text-right">
                          <span>{{ $t('elearning_config.training_title') }}</span>:
                          <strong>{{ search.training_title_id ? ($i18n.locale === 'en' ? searchHeaderData.training_title_en : searchHeaderData.training_title_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="datas.length > 0">
                            <b-tr>
                              <b-th style="width:7%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th style="width:10%" class="text-center">{{ $t('elearning_iabm.circular_memo_no') }}</b-th>
                              <b-th style="width:7%" class="text-center">{{ $t('globalTrans.organization') }}</b-th>
                              <b-th style="width:7%" class="text-center">{{ $t('globalTrans.fiscalYear') }}</b-th>
                              <b-th class="text-center">{{$t('elearning_config.training_title')}}</b-th>
                              <b-th class="text-center">{{$t('tpm_report.honorarium_amount')}}</b-th>
                              <b-th class="text-center">{{$t('tpm_report.allowance_amount')}}</b-th>
                              <b-th class="text-center">{{$t('tpm_report.training_income')}}</b-th>
                              <b-th class="text-center">{{$t('tpm_report.training_cost')}}</b-th>
                            </b-tr>
                            <b-tbody v-for="(info, index) in datas" :key="index">
                              <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                              <b-td class="text-center">{{ info.circular_memo_no }}</b-td>
                              <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.org_name_bn : info.org_name }}</b-td>
                              <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.fiscal_year_bn : info.fiscal_year }}</b-td>
                              <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.training_title_bn : info.training_title }}</b-td>
                              <b-td class="text-center">{{ $n(info.honorarium_amount) }}</b-td>
                              <b-td class="text-center">{{ $n(info.allowance_amount) }}</b-td>
                              <b-td class="text-center">{{ $n(info.income_amount) }}</b-td>
                              <b-td class="text-center">{{ $n(info.total_cost) }}</b-td>
                            </b-tbody>
                          </b-table-simple>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </template>
                </body-card>
              </b-col>
            </b-row>
            <div class="panel-body text-center mt-3" v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingSummryReport, circularPublicationList, circularPublication } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
// import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  created () {
    this.getCircularList()
    this.search.fiscal_year_id = this.$store.state.currentFiscalYearId
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      search: {
        org_id: 0,
        circular_memo_no: 0,
        training_type_id: 0,
        training_title_id: 0,
        fiscal_year_id: 0
      },
      searchHeaderData: {
        fiscal_year_en: '',
        fiscal_year_bn: '',
        org_en: '',
        org_bn: '',
        circular_memo_no: '',
        training_title_en: '',
        training_title_bn: ''
      },
      trainingTitleList: [],
      circularList: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      datas: [],
      circularLoading: false,
      loading: false,
      loadingData: false,
      showData: false
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  computed: {
    organizationList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    trainingTitleDataList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    i18 () {
      return this.$i18n.locale
    }
  },
  watch: {
    // 'search.circular_memo_no': function (newValue) {
    //   this.getCircularMemoNo(newValue)
    // },
    'search.training_type_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'search.fiscal_year_id': function (newValue) {
      this.getCircularList()
    }
  },
  methods: {
    async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoading = false
      },
    async getCircularMemoNo (getCircularMemoNo) {
      if (getCircularMemoNo) {
        this.loading = true
        const params = {
          circular_memo_no: getCircularMemoNo,
          table: 'tpm_training_calendar'
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
        if (!result.success) {
        } else {
          this.circularErrorMsg = false
          const trData = result.data
          this.search.training_title_id = trData.training_title_id
          this.search.training_type_id = trData.training_type_id
        }
        this.loading = false
      }
    },
    // pdfExport () {
    //   const reportTitle = this.$t('tpm_report.training_summary_report')
    //   ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search)
    // },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_type_id === categoryId)
      }
      return trainingTitleList
    },
    async searchData () {
      this.loadingData = true
      this.showData = true
      if (this.search.fiscal_year_id) {
        const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
        const customItem = {
          fiscal_year_en: fiscalYear?.text_en,
          fiscal_year_bn: fiscalYear?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
      }
      if (this.search.org_id) {
        const orgObj = this.organizationList.find(obj => obj.value === this.search.org_id)
        const orgData = {
          org_en: orgObj?.text_en,
          org_bn: orgObj?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, orgData)
      }
      if (this.search.circular_memo_no) {
        const cicularObj = this.circularList.find(obj => obj.value === this.search.circular_memo_no)
        const circularData = {
          circular_memo_no: cicularObj?.text
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, circularData)
      }
      if (this.search.training_title_id) {
        const trainingTitleObj = this.trainingTitleDataList.find(obj => obj.value === this.search.training_title_id)
        const trainingTitleData = {
          training_title_en: trainingTitleObj?.text_en,
          training_title_bn: trainingTitleObj?.text_bn
        }
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, trainingTitleData)
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingSummryReport, this.search)
      if (result.success) {
        if (result.data) {
          this.loadingData = false
          this.datas = this.getCustomDataList(result.data, result.material)
        }
      } else {
        this.datas = []
        this.loadingData = false
      }
    },
    getCustomDataList (data, material) {
      const listData = data.map(item => {
        const totalCost = material.find(cId => cId.circular_memo_no === item.circular_memo_no)
        const totalCostData = {}
        if (typeof totalCost !== 'undefined') {
          totalCostData.total_cost = totalCost.cost_amount
        } else {
          totalCostData.total_cost = ''
        }
        const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
        const fiscalYearData = {}
        if (typeof fiscalYearObj !== 'undefined') {
          fiscalYearData.fiscal_year = fiscalYearObj.text_en
          fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          fiscalYearData.fiscal_year = ''
          fiscalYearData.fiscal_year_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
        const trainingTitleData = {}
        if (typeof trainingTitleObj !== 'undefined') {
          trainingTitleData.training_title = trainingTitleObj.text_en
          trainingTitleData.training_title_bn = trainingTitleObj.text_bn
        } else {
          trainingTitleData.training_title = ''
          trainingTitleData.training_title_bn = ''
        }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
        const trainingTypeData = {}
        if (typeof trainingTypeObj !== 'undefined') {
          trainingTypeData.training_type = trainingTypeObj.text_en
          trainingTypeData.training_type_bn = trainingTypeObj.text_bn
        } else {
          trainingTypeData.training_type = ''
          trainingTypeData.training_type_bn = ''
        }
        const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
        const orgData = {}
        if (typeof orgObj !== 'undefined') {
          orgData.org_name = orgObj.text_en
          orgData.org_name_bn = orgObj.text_bn
        } else {
          orgData.org_name = ''
          orgData.org_name_bn = ''
        }
        return Object.assign({}, item, fiscalYearData, trainingTitleData, trainingTypeData, orgData, totalCostData)
      })
      return listData
    },
    async pdfExport () {
      this.customloading = true
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: this.search.org_id, fiscal_year_id: this.search.fiscal_year_id, organization_id: this.search.org_id, circular_memo_no: this.search.circular_memo_no, training_title_id: this.search.training_title_id })
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, trainingSummryReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.customloading = false
    }
  }
}
</script>
